<template>
  <v-container class="container--fluid grid-list-md">
    <v-row no-gutters>
      <v-col cols="12" class="my-2">
        <span class="font-weight-medium text-body-1 primary--text text--darken-1">{{ $t('bank.bankActions') }}</span>
      </v-col>
    </v-row>
    <v-row no-gutters v-if="(bankId && !!model.is_locked && model.user_id!==user.id)">
      <v-col cols="12" class="my-3">
        <span class="font-weight-medium text-body-1 primary--text text--darken-1 error--text" >*{{ model.user ? $t('common.documentReadOnlyModeNotice',{userId:model.user.name}) : '' }}</span>
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col cols="12">
        <v-card
          class="mx-auto"
          outlined
        >
          <v-card-title class="py-1">
            <h5 style="max-width: 220px;" :class="bankId ? 'px-1 text-truncate font-italic primary--text font-weight-bold' : 'primary--text text--darken-1'" class="font-weight-regular">{{ bankId ? originModel.name && `${bankId}. ${originModel.name}` : $t('bank.addBank') }}</h5>
            <v-spacer />
            <v-menu bottom v-if="bankId" :close-on-click="true" :offset-y="true">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  :color="remarksList && remarksList.length > 0 ? 'success' : 'primary'"
                  class="ma-2"
                  outlined
                  small
                  @click="remarksDialog = true"
                  :disabled="(accessRight.length < 2 && accessRight.includes('show'))"
                >
                  {{ $t("remarks.remarks") }}
                </v-btn>
                <v-btn color="primary" class=" ma-2" small outlined v-bind="attrs" v-on="on" :disabled="!valid || (!!model.is_locked && model.user_id!==user.id)">
                  <v-icon left>
                    mdi-menu-down
                  </v-icon>
                  {{$t('quotation.options')}}
                </v-btn>
              </template>
              <v-list>
                <v-list-item v-if="accessRight.includes('create')" dense class="primary--text" @click="copyBank">
                  <v-list-item-content>
                    <v-list-item-title>
                      <v-icon small class="primary--text">
                        mdi-content-copy
                      </v-icon>
                      {{$t('quotation.copy')}}
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item dense class="primary--text" @click="actDialog = true">
                  <v-list-item-content>
                    <v-list-item-title>
                      <v-icon small class="primary--text">
                        mdi-pulse
                      </v-icon>
                      {{$t('quotation.activityLog')}}
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item dense class="primary--text" @click="attachedDocumentsDialog = true">
                  <v-list-item-content>
                    <v-list-item-title>
                      <v-icon small class="primary--text">
                        mdi-file-document
                      </v-icon>
                      {{$t('employee.attachedDocuments')}}
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item dense v-if="accessRight.includes('delete')" class="error--text" @click="confirmationDialog = true, delete_item = bankId">
                  <v-list-item-content>
                    <v-list-item-title>
                      <v-icon small class="error--text">
                        mdi-delete-sweep-outline
                      </v-icon>
                      {{$t('common.delete')}}
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-menu>
            <v-btn
              color="primary"
              small
              :loading="loading"
              :disabled="!valid || (!!model.is_locked && model.user_id!==user.id) || (accessRight.length < 2 && accessRight.includes('show'))"
              @click="saveBank"
            >
              {{ $t('bank.save') }}
            </v-btn>
          </v-card-title>
          <v-divider/>
          <v-card-text class="text-h5 pa-2">
            <FormSkeleton v-if="bankId && Object.keys(bankById).length <= 0"/>
            <v-form
              v-else
              ref="form"
              v-model="valid"
              lazy-validation
              :disabled="(accessRight.length < 2 && accessRight.includes('show'))"
            >
              <Alert :alert.sync="alert" :message="message" />
              <v-row>
                <v-col cols="12">
                  <v-card
                    class="mx-auto"
                    flat
                  >
                    <v-card-title class="py-1 primary--text">
                      <v-icon color="primary">mdi-file-delimited-outline</v-icon>
                      <h6 class="ms-2 font-weight-regular">{{$t('bank.basicDetails')}}</h6>
                      <v-spacer />
                    </v-card-title>
                    <v-divider />
                    <v-card-text>
                      <v-row :dense="true">
                        <v-col cols="4">
                          <v-text-field
                            class="asterisk"
                            hide-details="auto"
                            v-model="model.name"
                            :label="$t('bank.field.name')"
                            :rules="nameValid"
                            required
                          />
                          <v-text-field
                            hide-details="auto"
                            v-model="model.email"
                            :label="$t('bank.field.email')"
                            :rules="emailValid"
                            required
                          />
                          <v-text-field
                            hide-details="auto"
                            v-model="model.branch_no"
                            :label="$t('bank.field.branchNo')"
                            :rules="[lessThanValidation($t('bank.field.branchNo'), $t('common.less'), 3, model.branch_no)]"
                            required
                          />
                          <v-text-field
                            hide-details="auto"
                            v-model="model.branch_name"
                            :label="$t('bank.field.branchName')"
                            :rules="[lessThanValidation($t('bank.field.branchName'), $t('common.less'), 50, model.branch_name)]"
                            required
                          />
                          <v-text-field
                            hide-details="auto"
                            v-model="model.account_no"
                            :label="$t('bank.field.accountNo')"
                            :rules="[lessThanValidation($t('bank.field.accountNo'), $t('common.less'), 16, model.account_no)]"
                            required
                          />
                        </v-col>
                        <v-col cols="4">
                          <v-text-field
                            hide-details="auto"
                            v-model="model.accounting_no"
                            :label="$t('bank.field.accountingNo')"
                            :rules="[lessThanValidation($t('bank.field.accountingNo'), $t('common.less'), 15, model.accounting_no)]"
                            required
                          />
                          <v-text-field
                            hide-details="auto"
                            v-model="model.contact_name"
                            :label="$t('bank.field.contactName')"
                            :rules="[lessThanValidation($t('bank.field.contactName'), $t('common.less'), 200, model.contact_name)]"
                            required
                          />
                          <v-text-field
                            hide-details="auto"
                            v-model="model.address"
                            :label="$t('bank.field.address')"
                            :rules="[lessThanValidation($t('bank.field.address'), $t('common.less'), 250, model.address)]"
                            required
                          />
                          <v-text-field
                            hide-details="auto"
                            v-model="model.town"
                            :label="$t('bank.field.town')"
                            :rules="[lessThanValidation($t('bank.field.town'), $t('common.less'), 250, model.town)]"
                            required
                          />
                          <v-text-field
                            hide-details="auto"
                            v-model="model.postal_code"
                            :label="$t('bank.field.postalCode')"
                            :rules="[lessThanValidation($t('bank.field.postalCode'), $t('common.less'), 10, model.postal_code)]"
                            required
                          />
                        </v-col>
                        <v-col cols="4">
                          <v-text-field
                            hide-details="auto"
                            v-model="model.phone_1"
                            :label="$t('bank.field.tel1')"
                            :rules="phoneValid"
                            required
                          />
                          <v-text-field
                            hide-details="auto"
                            v-model="model.phone_2"
                            :label="$t('bank.field.tel2')"
                            :rules="phoneValid"
                            required
                          />
                          <v-text-field
                            hide-details="auto"
                            v-model="model.phone_3"
                            :label="$t('bank.field.tel3')"
                            :rules="phoneValid"
                            required
                          />
                          <v-text-field
                            hide-details="auto"
                            v-model="model.fax"
                            :rules="faxValid"
                            :label="$t('bank.field.fax')"
                            required
                          />
                          <v-text-field
                            hide-details="auto"
                            v-model="model.site"
                            :label="$t('bank.field.site')"
                            :rules="[lessThanValidation($t('bank.field.site'), $t('common.less'), 50, model.site)]"
                            required
                          />
                        </v-col>
                      </v-row>
                    </v-card-text>

                    <v-card-title class="py-1 primary--text">
                      <v-icon color="primary">mdi-comment-text-multiple</v-icon>
                      <h6 class="ms-2 font-weight-regular">{{$t('remarks.remarks')}}</h6>
                      <v-spacer />
                    </v-card-title>
                    <v-divider />
                    <v-card-text>
                      <v-row :dense="true">
                        <v-col cols="12">
                          <v-textarea
                            class="mt-4"
                            hide-details="auto"
                            dense
                            v-model="model.general_remarks"
                            :label="$t('customer.field.generalRemarks')"
                          />
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
        </v-card>
      </v-col>
      <ActivityLogTableDialog
        :actDialog.sync="actDialog"
        :docId.sync="bankId"
        docType="Bank"
      />
      <RemarksDialog :dialog.sync="remarksDialog" :docId="bankId" :docType="2" />
      <AttachedDocumentsDialog :docId.sync="bankId" docType="Bank" :dialog.sync="attachedDocumentsDialog" />
      <confirmation-dialog :dialog.sync="confirmationDialog" :delete_item.sync="delete_item" @deleteItem="deleteItem"/>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex';
import {changedFormData} from '@/utils';
import FormSkeleton from '@/components/skeleton/FormSkeleton';
import Alert from "@/components/Alert";
import ActivityLogTableDialog from '@/components/activityLog/Dialogs/ActivityLogTableDialog.vue';
import RemarksDialog from "@/components/RemarksDialog";
import AttachedDocumentsDialog from "@/components/AttachedDocumentsDialog";
import ConfirmationDialog from '@/components/ConfirmationDialog';

export default {
  name: 'bankAction',
  components: {
    FormSkeleton,
    Alert,
    ActivityLogTableDialog,
    RemarksDialog,
    AttachedDocumentsDialog,
    ConfirmationDialog
  },
  data() {
    return {
      message: '',
      alert: false,
      loading: false,
      valid: false,
      model: {},
      originModel: {},
      actDialog: false,
      attachedDocumentsDialog: false,
      remarksDialog: false,
      confirmationDialog: false,
      delete_item: null,
    }
  },
  computed: {
    nameValid() {
      return [
        v => !!v || this.$t('formRules.bankNameRequired'),
        v => !v || (v && v.length <= 250) || this.$t('formRules.lengthValidMessage', { fieldName: this.$t('bank.field.name'), type: this.$t('common.less'), size: 250 }),
      ];
    },
    emailValid() {
      return [
        v => !v || /.+@.+\..+/.test(v) || this.$t('formRules.emailValidMessage'),
        v => !v || (v && v.length <= 64) || this.$t('formRules.lengthValidMessage', { fieldName: this.$t('bank.field.email'), type: this.$t('common.less'), size: 64 }),
      ];
    },
    phoneValid() {
      return [
        v => !v || /^[0-9]*$/.test(v) || this.$t('formRules.phoneNoInteger'),
        v => !v || (v && (v.length >= 7 && v.length <= 17)) || this.$t('formRules.phoneNoValidMessage'),
      ];
    },
    faxValid() {
      return [
        v => !v || /^[0-9]*$/.test(v) || this.$t('formRules.faxInteger'),
        v => !v || (v && (v.length >= 7 && v.length <= 17)) || this.$t('formRules.faxValidMessage'),
      ];
    },
    bankId() {
      return this.$route.params.bank_id
    },
    ...mapGetters({
      bankById: 'bank/bankById',
      locale: 'locale',
      bankQuery: 'bank/bankQuery',
      remarksList: 'remarks/remarksList',
      accessRight: 'accessRight',
      user: 'user',
      moreThanOneUserLogout: 'moreThanOneUserLogout'
    })
  },
  watch: {
    bankById() {
      if (this.$route.params.bank_id) {
        if (Object.keys(this.bankById).length > 0) {
          this.model = Object.assign({}, this.model, this.bankById)
          this.originModel = Object.assign({}, this.originModel, this.model);
        }
      } else if (this.$route.params.copiedModel) {
        if (Object.keys(this.bankById).length > 0) {
          this.model = Object.assign({}, this.model, this.bankById);
          delete this.model.created_at
          delete this.model.id;
          delete this.model.updated_at;
          this.originModel = Object.assign({}, this.originModel, this.model);
        }
      }
    },
    async moreThanOneUserLogout() {
      if (this.moreThanOneUserLogout && this.model.id) {
        await this.$store.dispatch('PostDocumentOpenAction',{table:'Bank',id:this.model.id,is_locked:false});
        this.$store.dispatch('LogOut');
        this.$router.push({ name: 'login' });
      }
    }
  },
  async created() {
    window.addEventListener('beforeunload', this.handler)
  },
  async mounted() {
    if (this.bankId) {
      this.$store.dispatch('remarks/GetRemarks', {where: 'document_type|'+2, where_and: 'document_id|'+this.bankId})
    }
    this.onMounteUpdated();
  },
  beforeDestroy() {
    if (this.model.id && !this.moreThanOneUserLogout && this.model.is_locked && this.model.user_id == this.user.id || this.model.id &&  !this.model.user_id && !this.moreThanOneUserLogout) {
      this.$store.dispatch('PostDocumentOpenAction',{table:'Bank',id:this.model.id,is_locked:false});
    }
    this.$refs.form ? this.$refs.form.reset() : '';
    this.model = {};
    this.dialog = false;
    this.$store.commit('bank/SET_BANK_BY_ID', {});
    this.$store.commit('SET_USER_LOGOUT', false);
    window.removeEventListener('beforeunload', this.handler);
  },
  methods: {
    async handler(event) {
      if (this.model.id && !this.moreThanOneUserLogout && this.model.is_locked && this.model.user_id == this.user.id || this.model.id && !this.model.user_id && !this.moreThanOneUserLogout) {
        await this.$store.dispatch('PostDocumentOpenAction',{table:'Bank',id:this.model.id,is_locked:false});
      }
      event.preventDefault();
    },
    async onMounteUpdated() {
      if (this.$route.params.bank_id) {
        await this.$store.dispatch('bank/GetBankById', this.$route.params.bank_id);
        if (!this.model.is_locked && this.$route.params.bank_id) {
          await this.$store.dispatch('PostDocumentOpenAction',{table:'Bank',id:this.$route.params.bank_id,is_locked:true});
        }
      } else if (this.$route.params.copiedModel) {
        // Copy bank
        await this.$store.dispatch('bank/GetBankById', this.$route.params.copiedModel);
      }
    },
    callBackFunction() {
      this.$router.push({
        name: "Bank",
        query: {
          ...this.bankQuery,
        },
      });
    },
    copyBank() {
      let copyModel = this.$route.params.bank_id;
      this.$router.push({
        name: 'addBank',
        params: {
          copiedModel: copyModel,
        },
      })
    },
    lessThanValidation(fieldValue, typeValue, sizeValue, v) {
      return !v || (v && String(v).length <= sizeValue) || this.$t('formRules.lengthValidMessage', { fieldName: fieldValue, type: typeValue, size: sizeValue });
    },
    async saveBank() {
      if(this.$refs.form.validate()){
        this.loading = true;
        let data;
        if(this.$route.params.bank_id) {
          // save only update model value using changedFormData from utils
          let saveData = changedFormData(this.model, this.originModel);
          data = await this.$store.dispatch('bank/EditBank', {bank_id: this.$route.params.bank_id, bank: saveData}).catch((e) => {
            this.loading = false;
            console.log(e)
          });
          if(data) {
              this.$refs.form.reset();
              this.loading = false;
              await this.onMounteUpdated();
            }
        } else {
          data = await this.$store.dispatch('bank/SetBank', this.model).catch((e) => {
            this.loading = false;
            console.log(e)
          });
          if(data) {
            this.$refs.form.reset();
            this.loading = false;
            if (this.accessRight.includes('edit')) {
              await this.$router.push('/bank/editBank/' + data.data.id).catch(()=>{});
            } else {
              this.callBackFunction();
            }
          }
        }
      } else {
        this.message = "formRules.formAlert";
        this.alert = true;
      }
    },
    async deleteItem() {
      await this.$store.dispatch('bank/DeleteBankItemById', this.bankId)
      this.$router.push("/bank")
    },
  }
}
</script>
